import React from 'react'
import find from 'lodash/find'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Hero from '../components/Hero'
import PageBody from '../components/PageBody'
import TagList from '../components/TagList'
import Tag from '../components/Tag'
import Stats from '../components/PostStats'
import PostDate from '../components/PostDate'
import SEO from '../components/SEO'
import Share from '../components/Share'
import Confession from '../components/Confession'
import PostComments from '../components/PostComments'
import Layout from '../components/Layout'


const ConfessionTemplate = ({pageContext}) => {
  const { node, next, previous } = pageContext
  const { createdAt, confession, id} = node

  return (
    <Layout>
      <Helmet>
        <title>{`${confession.childMarkdownRemark.excerpt} | ${config.siteTitle}`}</title>
        <meta property="og:url" content={`${config.siteUrl}/confessions/${id}/`} />
      </Helmet>

      <Confession
        id={id}
        date={createdAt}
        text={confession.childMarkdownRemark.rawMarkdownBody}
        comments
        navigation
        next={next !== null ? next: false}
        previous={previous !== null ? previous: false}
      />
    </Layout>
  )
}

export default ConfessionTemplate

import React from 'react'
import styled from 'styled-components'
import { FacebookProvider, Like } from 'react-facebook';
import config from '../utils/siteConfig'
import emoji from 'react-easy-emoji'
import PostComments from './PostComments'
import PostLinks from '../components/PostLinks'

const ConfessionWrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.bgSection};
  padding: calc(${props => props.theme.size.sectionSpacing} * 2);
  margin-bottom: ${props => props.theme.size.sectionSpacing};
  > * {
    margin-bottom: ${props => props.theme.size.sectionSpacing};
    :last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`

const ConfessionDate = styled.span`
  font-weight: 800;
`

const StyledFacebookProvider = styled.div`
  height:1.7em;
`

const Confession = ({text, date, id, comments, navigation, next, previous}) => {
  return(
    <ConfessionWrapper key={id}>
      <p><span>Confessed on </span><ConfessionDate>{date}</ConfessionDate></p>
        <p>{text}</p>
      <StyledFacebookProvider>
        <FacebookProvider appId={config.facebook}>
          <Like href={`${config.siteUrl}/confessions/${id}/`} colorScheme="light" layout="button_count" size="large"/>
        </FacebookProvider>
      </StyledFacebookProvider>
      {comments &&
        <PostComments slug={`confessions/${id}`}/>
      }
      {navigation &&
        <PostLinks nextSlug={next.id ? `confessions/${next.id}`:''} previousSlug={previous.id ? `confessions/${previous.id}`: ''}/>
      }
    </ConfessionWrapper>
  )
}

export default Confession
